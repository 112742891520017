<template>
  <div
      ref="ddNav"
      :class="{ open: toggle }"
      class="profile-nav trg"
  >
    <button
        :class="{ open: toggle }"
        aria-label="Menu"
        class="profile-nav_top trg_btn f fc btn_burger p0 wh52"
        @click="toggleNav()"
    >
      <span class=""></span>
    </button>
    <div class="profile-nav_bottom">
      <div class="overlay" @click="toggleNav()"></div>
      <div class="profile-nav_bottom__wrap">
        <FilterTopComponentsSearch v-if="currW <= 829" :header="true" />
        <nav class="nav_main">
          <ul class="list">
            <li class="item">
              <div
                  :class="{ open: typesToggle }"
                  class="item-types"
                  @click="typesToggle = !typesToggle"
              >
                <span class="type-list">
                  <TypeList :active="typesToggle" />
                  <span>{{ t('Items type') }}</span></span
                >
                <i class="i-arrow types-arrow"></i>
              </div>
              <transition>
                <Categories
                    v-if="currW < 1115 && typesToggle"
                    class="categories-show"
                    @close="toggleNav()"
                />
              </transition>
            </li>
            <li class="item" @click="toggleNav()">
              <NuxtLink :activeClass="'active'" :to="localePath('/')">
                <i class="i-analitycs"></i>
                <span>{{ t('main') }}</span>
              </NuxtLink>
            </li>
            <li class="item" @click="toggleNav()">
              <NuxtLink :activeClass="'active'" :to="localePath('/comparison')">
                <i class="i-comparison"></i>
                <span>{{ t('price_comparison') }}</span>
              </NuxtLink>
            </li>
            <li class="item" @click="toggleNav()">
              <NuxtLink :activeClass="'active'" :to="localePath('/discord')">
                <i class="i-discord"></i>
                <span>{{ t('discord_bot') }}</span>
              </NuxtLink>
            </li>
            <li class="item" @click="toggleNav()">
              <NuxtLink :activeClass="'active'" :to="localePath('/blog')">
                <svg height="20" width="20">
                  <use xlink:href="/icons/sprite.svg#i-blog"></use>
                </svg>
                <span>{{ t('blog') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <div v-if="currW <= 768" class="nav_footer">
          <HeaderComponentsLogin class="mb24" />
          <button v-if="user" class="btn btn-primary btn-red" @click="logout">
            {{ t('logout') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TypeList from '~/components/Icons/Components/TypeList.vue'

import {computed, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {useClick} from '~/composables/click.js'
import {useResize} from '~/composables/resize.js'
import {useStore} from '~/store/index.js'
import {useRuntimeConfig} from 'nuxt/app'
import {useLocalePath} from "#i18n";

const localePath = useLocalePath();
const {t} = useI18n()

const currW = useResize()

// use store
const store = useStore()

const user = computed(() => store.user)

const toggle = ref(false)
const toggleMore = ref(false)
const typesToggle = ref(false)

const toggleNav = () => {
  toggle.value = !toggle.value

  if (user.value) {
    toggleMore.value = false
  }

  typesToggle.value = false
}

const config = useRuntimeConfig()
const url = config.public.apiUrl

const logout = () => {
  return (document.location = url + '/logout')
}

const closeAll = () => {
  toggle.value = false
  toggleMore.value = false
  typesToggle.value = false
}


onMounted(() => {
  if (!user.value) {
    toggleMore.value = true
  }
})

watch(toggle, () => {
  if (!toggle.value) {
    typesToggle.value = false
  }
})

watch(currW, () => {
  if (currW.value > 1115) {
    closeAll()
  }
})

const ddNav = ref<HTMLElement>()

useClick({el: ddNav, toggle})

onBeforeUnmount(async () => {
  closeAll()
})
</script>

<style lang="scss" scoped>
@import '/assets/scss/mixins.scss';

.btn_exit {
  margin-left: 0.8rem;

  &:hover {
    i {
      color: darken($red, 5%);
    }
  }
}

.btn_burger {
  padding: 1.6rem;

  span {
    height: 2px;
    width: 100%;
    background: $gr-primary;
    position: relative;
    transition: 0.3s;

    &:before {
      content: '';
      display: block;
      height: inherit;
      width: inherit;
      background: inherit;
      position: absolute;
      left: 0;
      top: -6px;
      transition: 0.3s;
    }

    &:after {
      content: '';
      display: block;
      height: inherit;
      width: inherit;
      background: inherit;
      position: absolute;
      left: 0;
      bottom: -6px;
      transition: 0.3s;
    }
  }

  &.open {
    span {
      transform: rotate(-180deg);

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }
}

.profile-nav {
  &_top {
    position: absolute;
    z-index: 16;
    top: 0;
    right: 0;
    @include _1115 {
      top: 1.25rem;
    }
    @include _979 {
      top: 0;
    }
  }

  &_bottom {
    .overlay {
      position: fixed;
      top: 5.3rem;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparentize($dark, 0.5);
      backdrop-filter: blur(5px);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      display: none;
      @include _979 {
        display: block;
      }
    }

    &__wrap {
      position: fixed;
      z-index: 10;
      right: 0;
      background: $black;
      box-shadow: 0 16px 17px -10px #05060733;
      // border-radius: 0.8rem;
      // padding: 0.6rem;
      // width: 100%;
      // top: 5rem;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
      transition: 0.25s;

      height: 100%;
      top: 8rem;
      width: 32.2rem;
      padding: 1.6rem;
      border-radius: 1.2rem 0 1.2rem 1.2rem;
      overflow-y: auto;
      padding-bottom: 8rem;

      @include _979 {
        top: 5.7rem;
        .search_header .form_field input {
          height: 5.6rem;
        }
      }
    }
  }

  &.open {
    .profile-nav {
      &_top {
        i {
          transform: rotate(180deg);
        }
      }

      &_bottom {
        .overlay {
          opacity: 1;
          visibility: visible;
        }

        &__wrap {
          opacity: 1;
          visibility: visible;
          transform: unset;
        }
      }
    }
  }
}

.nav {
  &_main {
    width: 100%;

    .list {
      margin: 0.5rem 0 0.2rem;
    }

    .item {
      a,
      .item-types {
        transition: margin-bottom 0.3s ease-in-out;
        margin: 1.1rem 0;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: $light;
        padding: 1rem 2.6rem;
        border-radius: $radius-btn;
        height: 6.4rem;
        // border: $border;
        border: 1px solid $dark;
        cursor: pointer;

        i, svg {
          margin-right: 1rem;
          font-size: 1.8rem;
        }

        &:hover {
          color: $white;
          background: darken($dark, 2%);
        }

        .types-arrow {
          font-size: 1rem;
        }

        // &.nuxt-link-active,
        &.nuxt-link-exact-active,
        &.open {
          // color: $col-primary;
          // background: rgba(85, 58, 255, 0.1);
          background: linear-gradient(
                  101.99deg,
                  rgba(88, 226, 176, 0.1) -3.52%,
                  rgba(62, 176, 211, 0.1) 89.43%
          );
          border-radius: 1.2rem;

          span,
          i {
            background: linear-gradient(
                    101.99deg,
                    #58e2b0 -3.52%,
                    #3eb0d3 89.43%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            border-color: transparent;
          }

          .types-arrow {
            transform: rotate(180deg);
          }
        }

        &.open {
          margin-bottom: 2rem;
        }
      }

      .active {
        // color: $col-primary;
        // background: rgba(85, 58, 255, 0.1);
        &:hover {
          color: initial;
          background: linear-gradient(
                  101.99deg,
                  rgba(88, 226, 176, 0.1) -3.52%,
                  rgba(62, 176, 211, 0.1) 89.43%
          );
          // cursor: initial;
        }

        background: linear-gradient(
                101.99deg,
                rgba(88, 226, 176, 0.1) -3.52%,
                rgba(62, 176, 211, 0.1) 89.43%
        );
        border-radius: 1.2rem;

        span,
        i {
          background: linear-gradient(
                  101.99deg,
                  #58e2b0 -3.52%,
                  #3eb0d3 89.43%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          border-color: transparent;
        }

        .types-arrow {
          transform: rotate(180deg);
        }
      }

      .item-types {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;

        .type-list {
          display: flex;
          align-content: center;
          justify-content: center;
        }
      }

      .categories-show {
        @include _1115 {
          height: fit-content;
          margin-bottom: 2rem;
          opacity: 1;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
        }
      }

      .categories-show.v-enter-to,
      .categories-show.v-leave {
        max-height: 446px;
      }

      .categories-show.v-enter,
      .categories-show.v-leave-to {
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
      }

      // .categories-show.open {
      //   @include _1115 {
      //     visibility: visible;
      //     margin-bottom: 2rem;
      //     height: fit-content;
      //   }
      // }
    }
  }

  &_footer {
    width: 100%;
    // margin: 3.3rem 0;
    margin: 1.2rem 0;

    a,
    button {
      width: 100%;
      margin: auto !important;
    }
  }
}
</style>
